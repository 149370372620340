.App {
  text-align: center;
}

body,
h1 {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Rubik';
}

.App {
  font-family: sans-serif;
  min-height: 100vh;
}

footer {
  position: fixed;
  background-color: gray;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  text-align: center;
}

.header {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 0.5rem 1rem;
  background-image: linear-gradient(284deg, #59bd7b, #20abad);
  color: #fff;
  margin-bottom: 1rem;
}

.header a {
  color: #fff;
  text-decoration: none;
}

.header-title {
  font-size: 1.2rem;
  font-weight: normal;
}

.header-title::after {
  content: ' ▸ ';
  padding: 0 0.5rem;
}

.header-subtitle {
  font-size: 1.2rem;
}

.ais-SearchBox-input {
  width: 40vw;
  height: 5vh;
  min-height: 30px;
  padding: 10px;
  border-radius: 15px;
  margin-right: 10px;
  display: inline-block;
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  height: 5vh;
  min-height: 30px;
  width: 4vw;
  border-radius: 15px;
  margin-right: 10px;
}

.largeColumn {
  width: 150 !important;
  max-width: 150 !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

ais-highlight-0000000000 {
  background-color: rgba(158, 230, 183, 0.8);
}

.grid-filtro {
  display: grid;
  grid-column: 2;
  grid-template-rows: [row1-start] 40%;
  align-items: left;
  width: 80vw;
  margin-left: 10vw;
  height: 60px;
}

.p1 {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
  display: flex;
  font-weight: bold;
}

.ref1 {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 2;
}

.p2 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  display: flex;
  font-weight: bold;
}

.ref2 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
}

.ais-RefinementList-list {
  display: flex;
  text-align: left;
  list-style: none;
  align-items: left;
  display: flex;
  flex-direction: row;
  padding-left: 0px;
  margin-bottom: 0px;
}

.ais-RefinementList-item {
  margin-right: 15px;
}

.ais-RefinementList-count {
  font-weight: bold;
}