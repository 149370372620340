body {
  margin: 0;
  font-family: 'Rubik';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Rubik'
}

#search-input::placeholder{
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;  
  color: #3F4948;  
}

.btnAbreviacao{
  width: 32px;
  height: 17px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: bolder;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #00696B;  
}